import React from "react";
import iPhone from "../../assets/images/ogamoni_iPhone.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import instagram from "../../assets/images/instagram.png";

const navigation = [
  {
    name: "Facebook",
    href: "https://facebook.com/ogamonihq",
    icon: facebook,
  },
  {
    name: "Instagram",
    href: "https://instagram.com/ogamonihq",
    icon: instagram,
  },
  {
    name: "LinkedIn",
    href: "https://linkedin.com/company/ogamonihq",
    icon: linkedin,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/ogamonihq",
    icon: twitter,
  },
];
const WaitlistFooter = () => {
  const date = new Date().getFullYear();
  return (
    <footer className="bottom-0 fixed w-full">
      <div>
        <div className="max-w-7xl mx-auto pt-8  px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 ">
          <div className="flex justify-center space-x-6 xl:order-3 lg:order-3">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">{item.name}</span>
                <img
                  src={item.icon}
                  className="z-50"
                  aria-hidden="true"
                  alt="ogamoni"
                />
              </a>
            ))}
          </div>

          <div className="mt-2  xl:order-1 lg:order-1 md:order-2">
            <p className="text-center text-base text-white">
              <a href="/" rel="noopener noreferrer">
                &copy; {date} Ogamoni Technologies ltd.
              </a>
            </p>
          </div>
          <div className="hidden lg:block text-center xl:order-2 lg:order-2 md:order-1 ">
            <div className="flex justify-center items-center pt-4 -mb-24  ">
              <img className="h-70 w-full" src={iPhone} alt="ogamoni" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default WaitlistFooter;
