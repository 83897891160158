// AUTHENTICATION
export const WAITLIST_SUCCESSFUL = "WAITLIST_SUCCESSFUL";
export const WAITLIST_FAILED = "WAITLIST_FAILED";
export const CLEAR_WAITLIST = "CLEAR_WAITLIST";

// export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
// export const REGISTER_FAIL = "REGISTER_FAIL";
// export const USER_LOADED = "USER_LOADED";
// export const AUTH_ERROR = "AUTH_ERROR";
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// export const LOGIN_FAIL = "LOGIN_FAIL";
// export const LOGOUT = "LOGOUT";
// export const CLEAR_ERRORS = "CLEAR_ERRORS";

// PROJECTS
// export const GETALL_SUCCESS = "GETALL_SUCCESS";
// export const GETALL_FAIL = "GETALL_FAIL";
// export const ADD_SUCCESS = "ADD_SUCCESS";
// export const ADD_FAIL = "ADD_FAIL";
// export const DELETE_SUCCESS = "DELETE_SUCCESS";
// export const DELETE_FAIL = "DELETE_FAIL";
