import { Disclosure } from "@headlessui/react";
import Ogamoni from "../../assets/images/logo_ogamoni.png";

const WaitlistNav = () => {
  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="flex-1 flex items-center justify-center ">
                <div className="flex-shrink-0 flex items-center">
                  <a href="/" rel="noopener noreferrer">
                    <img className="z-50" src={Ogamoni} alt="ogamoni" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default WaitlistNav;
