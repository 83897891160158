import React, { useState, useContext, useLayoutEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import { useSnackbar } from "react-simple-snackbar";
import WaitlistFooter from "./WaitlistFooter";

const options = {
  position: "top-center",
  style: {
    backgroundColor: "white",
    border: "2px solid white",
    color: "red",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "5px",
    textAlign: "center",
  },
  closeStyle: {
    color: "red",
    fontSize: "16px",
  },
};
const WaitlistBody = ({ setMailModal }) => {
  const [openSnackbar] = useSnackbar(options);

  const authContext = useContext(AuthContext);

  const { waitlist, message, clearWaitlist } = authContext;
  const [user, setUser] = useState({
    email: "",
  });

  const { email } = user;

  useLayoutEffect(() => {
    if (message === "user subscribed successfully") {
      setMailModal(true);
    }

    setTimeout(() => {
      clearWaitlist();
      setMailModal(false);
    }, 60000);

    // eslint-disable-next-line
  }, [message]);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const regexEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (email === "") {
      return openSnackbar(<p>Kindly fill all fields</p>, 2000);
    } else if (!regexEmail.test(email)) {
      return openSnackbar(<p>Invalid Email Address</p>, 2000);
    } else {
      setMailModal(true);
      waitlist({
        email: email,
        status: "subscribed",
      });
    }
  };
  return (
    <>
      <div className="flex justify-center items-center  flex-col pt-8  px-2  ">
        <div>
          <h1 className="text-4xl text-center font-extrabold text-white">
            Manage your finance <br />
            <p> without stress!</p>
          </h1>
          <p className=" py-2 text-xs text-white font-light text-center">
            Be the first to use our all in one personal finance app, that helps
            you get reminded for bills, budget, save and invest and
            <br /> a lot more.
          </p>
        </div>
        <div>
          <form>
            <div className="mt-1 relative flex items-center">
              <input
                autoComplete="off"
                aria-autocomplete="none"
                type="email"
                name="email"
                value={email}
                onChange={onChange}
                placeholder="Enter your email address"
                className=" py-5 pl-3 bg-transparent text-gray-200 border-2 outline-none pr-48 placeholder:text-white block w-full  text-xs border-gray-200 rounded-xl"
              />
              <div className="absolute  right-0 flex py-3 pr-2">
                <p
                  onClick={onSubmit}
                  className="inline-flex bg-gray-200   rounded-xl cursor-pointer items-center border border-gray-200  px-3 py-3 text-sm foitnt-sans font-normal text-gray-400"
                >
                  Join Waitlist
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <WaitlistFooter />
    </>
  );
};

export default WaitlistBody;
